import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


export default () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data.file.childImageSharp.fluid}
  />
  )
}
